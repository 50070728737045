import React from "react";
import { Navigate } from "react-router-dom";
import ForgotPassword from "src/pages/auth/forgot-password";
import Login from "src/pages/auth/login";
import VerifyOtp from "src/pages/auth/verify-otp";
import ResetPassword from "src/pages/auth/reset-password";
import ResetPasswordSuccess from "src/pages/auth/reset-password-success";
import MarginGroups from "src/pages/margin-groups";
import Settings from "src/pages/settings";
import UserManagement from "src/pages/user-management";
import AdminUsersManagement from "src/pages/admin-user-management";
import CostGroups from "src/pages/margin-groups/elements/cost-groups";
import Prices from "src/pages/prices";
import Bookings from "src/pages/user-management/elements/customers/bookings";
import ConnectDatabase from "src/pages/prices/elements/connect-database";
import UserQuotations from "src/pages/user-management/elements/customers/user-quotations";
import { Quotations } from "src/pages/quotations";
import QuotationsResult from "src/pages/quotation-results";

// Customer Routes
import CustomerLogin from "src/pages/customer/auth/login";
import CustomerSignUp from "src/pages/customer/auth/sign-up";
import CustomerForgotPassword from "src/pages/customer/auth/forgot-password";
import CustomerResetPassword from "src/pages/customer/auth/reset-password";
import CustomerResetPasswordSuccess from "src/pages/customer/auth/reset-password/success";
import { Quotations as CusQuotation } from "src/pages/customer/quotations-2";
import CusBookings from "src/pages/customer/bookings";
import HelpSupport from "src/pages/customer/help-support";
import ConfirmQuotation from "src/pages/customer/confirm-quotation";
import CustomerQuotationsResultComponent from "src/pages/customer/quotation-results";
import BookingDetails from "src/pages/customer/bookings/details";
import Users from "src/pages/user-management/elements/customers/users";
import PendingApproval from "src/pages/customer/auth/pending-approval";
import Rejected from "src/pages/customer/auth/rejected";

interface IRoutes {
  path: string;
  component: React.FC;
  isProtected: boolean;
}

/* This code is defining an array of protected and public routes for our application. The
 arrays contains an object with three properties: `path` (a string representing
the URL path for the route), `component` (a React component to render when the route is accessed),
and `isProtected` (a boolean indicating whether the route requires authentication to access). In
this case, the `Dashboard` component is protected and can only be accessed if the user is
authenticated. */

const commonRoutes: IRoutes[] = [
  {
    path: "/",
    component: () => <Navigate to="/login" />,
    isProtected: false,
  },
  {
    path: "/admin/login",
    component: Login,
    isProtected: false,
  },
  {
    path: "/admin/forgot-password",
    component: ForgotPassword,
    isProtected: false,
  },
  {
    path: "/admin/verify-otp",
    component: VerifyOtp,
    isProtected: false,
  },
  {
    path: "/admin/reset-password",
    component: ResetPassword,
    isProtected: false,
  },
  {
    path: "/admin/reset-password-success",
    component: ResetPasswordSuccess,
    isProtected: false,
  },
];

const adminRoutes: IRoutes[] = [
  {
    path: "/admin/quotations",
    component: Quotations,
    isProtected: true,
  },
  {
    path: "/admin/margin-groups",
    component: MarginGroups,
    isProtected: true,
  },
  {
    path: "/admin/settings",
    component: Settings,
    isProtected: true,
  },
  {
    path: "/admin/customer-management",
    component: UserManagement,
    isProtected: true,
  },
  {
    path: "/admin/customer-management/bookings",
    component: Bookings,
    isProtected: true,
  },
  {
    path: "/admin/customer-management/quotations",
    component: UserQuotations,
    isProtected: true,
  },
  {
    path: "/admin/customer-management/users",
    component: Users,
    isProtected: true,
  },
  {
    path: "/admin/admin-user-management",
    component: AdminUsersManagement,
    isProtected: true,
  },
  {
    path: "/admin/margin-groups/cost-groups",
    component: CostGroups,
    isProtected: true,
  },
  {
    path: "/admin/prices",
    component: Prices,
    isProtected: true,
  },
  {
    path: "/admin/prices/connect-database",
    component: ConnectDatabase,
    isProtected: true,
  },
  {
    path: "/admin/quotations/results",
    component: QuotationsResult,
    isProtected: true,
  },
];

const customerRoutes = [
  // Public Routes
  {
    path: "/login",
    component: CustomerLogin,
    isProtected: false,
  },
  {
    path: "/pending-approval",
    component: PendingApproval,
    isProtected: false,
  },
  {
    path: "/rejected",
    component: Rejected,
    isProtected: false,
  },
  {
    path: "/sign-up",
    component: CustomerSignUp,
    isProtected: false,
  },
  {
    path: "/forgot-password",
    component: CustomerForgotPassword,
    isProtected: false,
  },
  {
    path: "/verify-otp",
    component: VerifyOtp,
    isProtected: false,
  },
  {
    path: "/reset-password",
    component: CustomerResetPassword,
    isProtected: false,
  },
  {
    path: "/reset-password-success",
    component: CustomerResetPasswordSuccess,
    isProtected: false,
  },

  // Protected Routes
  {
    path: "/quotations",
    component: CusQuotation,
    isProtected: true,
  },
  {
    path: "/quotations/results",
    component: CustomerQuotationsResultComponent,
    isProtected: true,
  },
  {
    path: "/bookings",
    component: CusBookings,
    isProtected: true,
  },
  {
    path: "/bookings/details/:id",
    component: BookingDetails,
    isProtected: true,
  },
  {
    path: "/help-and-support",
    component: HelpSupport,
    isProtected: true,
  },
  {
    path: "/confirm-quotation/:id",
    component: ConfirmQuotation,
    isProtected: true,
  },
];

export { commonRoutes, adminRoutes, customerRoutes };
